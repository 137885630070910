import React from 'react';
import type { EditorView, NodeView } from '@atlaskit/editor-prosemirror/view';
import type { Node as PmNode } from '@atlaskit/editor-prosemirror/model';
import type { EditorProps } from '@atlaskit/editor-core';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { ExtensionHandlers } from '@atlaskit/editor-common/extensions';
import {
	ExtensionNode,
	ExtensionNodeWrapper,
	type MacroInteractionDesignFeatureFlags,
} from '@atlaskit/editor-common/extensibility';
import ExtensionWithDataSource from '../ui/Extension';
import type { DataSourceProvider } from '../data-source-provider';
import type { WidthPlugin } from '@atlaskit/editor-plugin-width';
import type {
	EditorAppearance,
	getPosHandler,
	PublicPluginAPI,
} from '@atlaskit/editor-common/types';
import type { EventDispatcher } from '@atlaskit/editor-common/event-dispatcher';
import { getPluginState } from '../plugins/plugin-key';
import { type PortalProviderAPI } from '@atlaskit/editor-common/portal';
import type { ADFEntity } from '@atlaskit/adf-utils/types';

export type ForwardRef = (node: HTMLElement | null) => void;

/**
 * @description This class extends editor-core ExtensionNode passing dataSourceProvider
 * and rendering ExtensionWithDataSource component
 */
export class ExtensionNodeWithDataSource extends ExtensionNode {
	render(
		props: {
			providerFactory: ProviderFactory;
			extensionHandlers: ExtensionHandlers;
			dataSourceProvider?: DataSourceProvider;
			appearance?: NonNullable<EditorProps['appearance']>;
			pluginInjectionApi: PublicPluginAPI<[WidthPlugin]> | undefined;
			macroInteractionDesignFeatureFlags?: MacroInteractionDesignFeatureFlags;
			showLivePagesBodiedMacrosRendererView?: (node: ADFEntity) => boolean;
			showUpdatedLivePages1PBodiedExtensionUI?: (node: ADFEntity) => boolean;
			rendererExtensionHandlers?: ExtensionHandlers;
		},
		forwardRef: ForwardRef,
	) {
		return (
			<ExtensionNodeWrapper
				nodeType={this.node.type.name}
				macroInteractionDesignFeatureFlags={props.macroInteractionDesignFeatureFlags}
			>
				<ExtensionWithDataSource
					editorAppearance={props.appearance}
					editorView={this.view as EditorView}
					node={this.node}
					// The getPos arg is always a function when used with nodes
					// the version of the types we use has a union with the type
					// for marks.
					// This has been fixed in later versions of the definitly typed
					// types (and also in prosmirror-views inbuilt types).
					// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/57384
					getPos={this.getPos as () => number}
					providerFactory={props.providerFactory}
					handleContentDOMRef={forwardRef}
					extensionHandlers={props.extensionHandlers}
					dataSourceProvider={props.dataSourceProvider}
					pluginInjectionApi={props.pluginInjectionApi}
					macroInteractionDesignFeatureFlags={props.macroInteractionDesignFeatureFlags}
					showLivePagesBodiedMacrosRendererView={props.showLivePagesBodiedMacrosRendererView}
					showUpdatedLivePages1PBodiedExtensionUI={props.showUpdatedLivePages1PBodiedExtensionUI}
					rendererExtensionHandlers={props.rendererExtensionHandlers}
				/>
			</ExtensionNodeWrapper>
		);
	}
}

export default function ExtensionNodeView(
	portalProviderAPI: PortalProviderAPI,
	eventDispatcher: EventDispatcher,
	providerFactory: ProviderFactory,
	extensionHandlers: ExtensionHandlers,
	appearance: EditorAppearance | undefined,
	pluginInjectionApi: PublicPluginAPI<[WidthPlugin]> | undefined,
	macroInteractionDesignFeatureFlags?: MacroInteractionDesignFeatureFlags,
	showLivePagesBodiedMacrosRendererView?: (node: ADFEntity) => boolean,
	showUpdatedLivePages1PBodiedExtensionUI?: (node: ADFEntity) => boolean,
	rendererExtensionHandlers?: ExtensionHandlers,
) {
	return (node: PmNode, view: EditorView, getPos: getPosHandler): NodeView => {
		const pluginState = getPluginState(view.state);

		return new ExtensionNodeWithDataSource(node, view, getPos, portalProviderAPI, eventDispatcher, {
			providerFactory,
			extensionHandlers,
			appearance,
			dataSourceProvider: pluginState?.dataSourceProvider,
			pluginInjectionApi,
			macroInteractionDesignFeatureFlags,
			showLivePagesBodiedMacrosRendererView,
			showUpdatedLivePages1PBodiedExtensionUI,
			rendererExtensionHandlers,
		}).init();
	};
}

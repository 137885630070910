import type { ADNode } from '@atlaskit/editor-common/validator';

import type { MacroName } from './extensions-common/types';

// Note: Please also update next/packages/content-renderer/src/ssr/getSSRExtensionHandlers.tsx whenever the type here updates.
export const EXTENSION_TYPE = {
	TEMPLATE: 'com.atlassian.confluence.template',
	MACRO_CORE: 'com.atlassian.confluence.macro.core',
	MIGRATION: 'com.atlassian.confluence.migration',
	// used for all macros that use Extension API and require references to be rendered
	MACRO_WITH_REFERENCES: 'com.atlassian.confluence.macro.with.references',
};

export const MIGRATION_EXTENSION_KEYS = {
	INVALID: '__confluenceADFMigrationUnsupportedContentInternalExtension__',
};

export const FALLBACK_REASONS = {
	UNSUPPORTED_EXTENSION: 'unsupportedExtension',
	INVALID_ADF: 'invalidAdf',
};

export const REGULAR_EXTENSION_TYPE = 'extension';
export const INLINE_EXTENSION_TYPE = 'inlineExtension';
export const BODIED_EXTENSION_TYPE = 'bodiedExtension';
export const MULTI_BODIED_EXTENSION_TYPE = 'multiBodiedExtension';

export const EMPTY_ADF_CONTENT: ADNode[] = [{ content: [], type: 'paragraph' }];
export const MULTI_BODIED_INITIAL_CONTENT: ADNode[] = [
	{
		type: 'extensionFrame',
		content: [
			{
				type: 'paragraph',
				content: [],
			},
		],
	},
];

export const EXTENSION_KEY = {
	TEMPLATE: 'variable',
	INLINE_EXTERNAL_IMAGE: 'inline-external-image',
	INLINE_MEDIA_IMAGE: 'inline-media-image',
	PRIVACY_MARK: 'privacy-mark',
	ANCHOR: 'anchor',
	PANEL: 'panel',
	SECTION: 'section',
	CHEESE: 'cheese',
	COLUMN: 'column',
	CONTENT_BY_LABEL: 'contentbylabel',
	CONTENT_BY_USER: 'content-by-user',
	CREATE_SPACE_BUTTON: 'create-space-button',
	FAVORITE_PAGES: 'favpages',
	GLOBAL_REPORTS: 'global-reports',
	IM_PRESENCE: 'im',
	LOREMIPSUM: 'loremipsum',
	NAVIGATION_MAP: 'navmap',
	RECENTLY_UPDATED: 'recently-updated',
	RECENTLY_USED_LABELS: 'recently-used-labels',
	SEARCH: 'search',
	SPACE_DETAILS: 'space-details',
	INVALID: '__confluenceADFMigrationUnsupportedContentInternalExtension__',
	REDACTED_MACRO_PLACEHOLDER: 'redactedMacroPlaceholder',
	TOC: 'toc',
	LIVE_SEARCH: 'livesearch',
	CREATE_FROM_TEMPLATE: 'create-from-template',
	CONFLUENCE_DATABASES: 'database-extension',
	DETAILS: 'details',
	DETAILS_SUMMARY: 'detailssummary',
	EXPAND: 'expand',
	EXCERPT: 'excerpt',
	EXCERPT_INCLUDE: 'excerpt-include',
	CHILDREN: 'children',
	PROFILE_PICTURE: 'profile-picture',
	PROFILE: 'profile',
};

export const allowedMacrosForInsertMenu: MacroName[] = [
	'toc',
	'expand',
	'trello-board',
	'status',
	'jiraroadmap',
];

export const serverRenderedExtensions: MacroName[] = [
	EXTENSION_KEY.TOC,
	EXTENSION_KEY.ANCHOR,
	EXTENSION_KEY.LIVE_SEARCH,
	EXTENSION_KEY.DETAILS_SUMMARY,
	EXTENSION_KEY.DETAILS,
	EXTENSION_KEY.EXCERPT,
	EXTENSION_KEY.EXPAND,
	EXTENSION_KEY.EXCERPT_INCLUDE,
	EXTENSION_KEY.CREATE_FROM_TEMPLATE,
	EXTENSION_KEY.REDACTED_MACRO_PLACEHOLDER,
	EXTENSION_KEY.CONFLUENCE_DATABASES,
	EXTENSION_KEY.PROFILE_PICTURE,
	EXTENSION_KEY.PROFILE,
];

export const supportedExtensions = new Set([
	REGULAR_EXTENSION_TYPE,
	INLINE_EXTENSION_TYPE,
	BODIED_EXTENSION_TYPE,
]);

// Fragment limited to 255 in backend.  Moving this to frontend (here).
export const MAX_ATLASSIAN_ALLOWED_FRAGMENT_LENGTH = 255;

export const MODERENIZED_MACRO_KEYS = [
	EXTENSION_KEY.CHILDREN,
	EXTENSION_KEY.EXCERPT,
	EXTENSION_KEY.EXCERPT_INCLUDE,
	EXTENSION_KEY.CONTENT_BY_LABEL,
	EXTENSION_KEY.RECENTLY_UPDATED,
];

// TODO: remove this code when cleaning up deprecating_low_usage_macros FG after macro removal on Sep 25 2025
export const DEPRECATED_MACROS = [
	'chart',
	'contributors-summary',
	'gallery',
	'google-drive-docs', // Google Drive for Confluence macros
	'google-drive-sheets',
	'google-drive-slides',
	'index', // Page Index
	'viewppt', // PowerPoint macro
	'viewxls', // Excel macro
	'viewdoc', // Word macro
	'recently-updated-dashboard',
	'related-labels',
	'spaces', // Spaces List
	'span',
	'userlister', // User List
];
export const MACRO_DEPRECATION_INFO_URL =
	'https://support.atlassian.com/confluence-cloud/docs/learn-which-macros-are-being-removed/';

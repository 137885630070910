/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import {
	BODIED_EXTENSION_TYPE,
	REGULAR_EXTENSION_TYPE,
} from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import type { ExtensionHandlerProps } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { getContentConverterVariables } from '@confluence/fabric-extension-queries';

import type {
	useDataMacroBodyContentConverterQuery as useDataMacroBodyContentConverterQuery$data,
	useDataMacroBodyContentConverterQueryVariables as useDataMacroBodyContentConverterQuery$variables,
} from './__types__/useDataMacroBodyContentConverterQuery';

export function useDataMacroBody(
	node: ExtensionHandlerProps['node'],
	contentId: string,
): {
	value?: string | null;
	missing?: boolean;
} {
	const bodyContentParam = node.parameters?.macroParams?.__bodyContent;
	const isPlainText = node.type === REGULAR_EXTENSION_TYPE && Boolean(bodyContentParam);
	const isRichTextMacro = node.type === BODIED_EXTENSION_TYPE;
	const contentConverterQueryResult = useQuery<
		useDataMacroBodyContentConverterQuery$data,
		useDataMacroBodyContentConverterQuery$variables
	>(
		gql`
			query useDataMacroBodyContentConverterQuery(
				$content: String!
				$contentId: ID!
				$from: String!
				$to: String!
				$spaceKeyContext: String = ""
			) {
				contentConverter(
					from: $from
					to: $to
					content: $content
					contentIdContext: $contentId
					spaceKeyContext: $spaceKeyContext
				) {
					value
				}
			}
		`,
		{
			variables: getContentConverterVariables({
				content: node.content,
				contentId,
			}),
			fetchPolicy: 'cache-only',
			skip: !isRichTextMacro,
		},
	);

	if (isRichTextMacro) {
		return contentConverterQueryResult.data
			? { value: contentConverterQueryResult.data.contentConverter?.value }
			: { missing: true };
	}

	if (isPlainText) {
		return { value: (bodyContentParam?.value as string) || '' };
	}

	return { value: '' };
}

import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	basicTabLabel: {
		id: 'fabric-extension-lib.recently-updated.tab.basic.label',
		defaultMessage: 'Basic',
		description: 'Label for tab containing general configuration options',
	},
	displayTabLabel: {
		id: 'fabric-extension-lib.recently-updated.tab.display.label',
		defaultMessage: 'Display',
		description: 'Label for tab containing display configuration options',
	},
	spaceLabel: {
		id: 'fabric-extension-lib.recently-updated.field.spaces.label',
		defaultMessage: 'Space',
		description: 'Label for search field to find a space name',
	},
	spacePlaceholder: {
		id: 'fabric-extension-lib.recently-updated.field.spaces.placeholder',
		defaultMessage: 'Search and select by name',
		description: 'Placeholder text within the search field to find a space name',
	},
	typesLabel: {
		id: 'fabric-extension-lib.recently-updated.field.types.label',
		defaultMessage: 'Include in list',
		description: 'Label for dropdown field to select content types to include in the list',
	},
	typePlaceholder: {
		id: 'fabric-extension-lib.recently-updated.field.types.placeholder',
		defaultMessage: 'Select one or several',
		description:
			'Placeholder text within the dropdown field to select content types to include in the list',
	},
	maxToggleLabel: {
		id: 'fabric-extension-lib.recently-updated.field.maxToggle.label',
		defaultMessage: 'Show all updates',
		description: 'Label for toggle to show the maximum number of updates in the list',
	},
	maxLabel: {
		id: 'fabric-extension-lib.recently-updated.field.max.label',
		defaultMessage: 'Max number of updates',
		description: 'Label for number field to set the number of updates to show in the list',
	},
	themeLabel: {
		id: 'fabric-extension-lib.recently-updated.field.theme.label',
		defaultMessage: 'List style',
		description: 'Label for dropdown field to set the display format of list items',
	},
	authorLabel: {
		id: 'fabric-extension-lib.recently-updated.field.author.label',
		defaultMessage: 'Filter by user',
		description: 'Label for search field to filter list items by author(s)',
	},
	authorPlaceholder: {
		id: 'fabric-extension-lib.recently-updated.field.author.placeholder',
		defaultMessage: 'Search and select user(s)',
		description: 'Placeholder within search field to filter list items by author(s)',
	},
	labelsLabel: {
		id: 'fabric-extension-lib.recently-updated.field.labels.label',
		defaultMessage: 'Filter by label',
		description: 'Label for search field to filter list items by label tags',
	},
	labelsPlaceholder: {
		id: 'fabric-extension-lib.recently-updated.field.labels.placeholder',
		defaultMessage: 'Search and select label(s)',
		description: 'Placeholder within search field to filter list items by label tags',
	},
	widthLabel: {
		id: 'fabric-extension-lib.recently-updated.field.width.label',
		defaultMessage: 'List width',
		description: 'Label for custom dropdown field to set the width of the list',
	},
	widthPlaceholder: {
		id: 'fabric-extension-lib.recently-updated.field.width.placeholder',
		defaultMessage: 'Select or enter width',
		description: 'Placeholder within custom dropdown field to set the width of the list',
	},
	hideHeadingLabel: {
		id: 'fabric-extension-lib.recently-updated.field.hideHeading.label',
		defaultMessage: 'Hide list header',
		description: "Label for checkbox to hide the 'Recently Updated' header above the list",
	},
});

export const i18nTypes = defineMessages({
	allLabel: {
		id: 'fabric-extension-lib.recently-updated.field.types.item.all',
		defaultMessage: 'All',
		description: 'Drop down option for content types field to show all content types in the list',
	},
	pageLabel: {
		id: 'fabric-extension-lib.recently-updated.field.types.item.page',
		defaultMessage: 'Pages',
		description: 'Drop down option for content types field to show pages in the list',
	},
	livePageLabel: {
		id: 'fabric-extension-lib.recently-updated.field.types.item.live-page',
		defaultMessage: 'Live docs',
		description: 'Drop down option for content types field to show live pages in the list',
	},
	blogPostLabel: {
		id: 'fabric-extension-lib.recently-updated.field.types.item.blogpost',
		defaultMessage: 'Blog posts',
		description: 'Drop down option for content types field to show blog posts in the list',
	},
	commentLabel: {
		id: 'fabric-extension-lib.recently-updated.field.types.item.comment',
		defaultMessage: 'Comments',
		description: 'Drop down option for content types field to show comments in the list',
	},
	attachmentLabel: {
		id: 'fabric-extension-lib.recently-updated.field.types.item.attachment',
		defaultMessage: 'Attachments',
		description: 'Drop down option for content types field to show attachments in the list',
	},
});

export const i18nTheme = defineMessages({
	conciseLabel: {
		id: 'fabric-extension-lib.recently-updated.field.theme.item.concise',
		defaultMessage: 'Title + user name',
		description:
			"Drop down option for theme field to display the content title and author's user name by each item",
	},
	sidebarLabel: {
		id: 'fabric-extension-lib.recently-updated.field.theme.item.sidebar',
		defaultMessage: 'Title',
		description: 'Drop down option for theme field to display the content title by each item',
	},
	socialLabel: {
		id: 'fabric-extension-lib.recently-updated.field.theme.item.social',
		defaultMessage: 'User name + profile picture',
		description:
			"Drop down option for theme field to display the author's user name and their profile picture by each item",
	},
});

export const i18nWidth = defineMessages({
	fullLabel: {
		id: 'fabric-extension-lib.recently-updated.field.theme.item.full',
		defaultMessage: '100% of frame',
		description: 'Drop down option for width field to set the list width to 100% of its frame',
	},
	threeQuartersLabel: {
		id: 'fabric-extension-lib.recently-updated.field.theme.item.threeQuarters',
		defaultMessage: '75% of frame',
		description: 'Drop down option for width field to set the list width to 75% of its frame',
	},
	halfLabel: {
		id: 'fabric-extension-lib.recently-updated.field.theme.item.half',
		defaultMessage: '50% of frame',
		description: 'Drop down option for width field to set the list width to 50% of its frame',
	},
	quarterLabel: {
		id: 'fabric-extension-lib.recently-updated.field.theme.item.quarter',
		defaultMessage: '25% of frame',
		description: 'Drop down option for width field to set the list width to 25% of its frame',
	},
});

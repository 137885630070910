import React from 'react';

import SVG from '@atlaskit/icon/svg';
import type { IconProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

export const ChildMacroIcon = (props: IconProps) => (
	<SVG size="large" secondaryColor={token('color.background.accent.gray.subtler')} {...props}>
		{/* Scaling the svg to 24x24 to match the design system. Keeping the viewBox at 32x32 to keep the proportions of the original icon. */}
		<svg
			width="24"
			height="24"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="child-macro-icon"
		>
			<rect
				x="6"
				y="7"
				width="23"
				height="18"
				rx="2"
				fill={token('color.background.accent.blue.subtlest.hovered')}
			/>
			<rect x="9" y="10" width="2" height="2" rx="1" fill={token('color.link')} />
			<rect x="13" y="10" width="13" height="2" rx="1" fill={token('color.link')} />
			<rect x="9" y="15" width="2" height="2" rx="1" fill={token('color.link')} />
			<rect x="13" y="15" width="13" height="2" rx="1" fill={token('color.link')} />
			<rect x="9" y="20" width="2" height="2" rx="1" fill={token('color.link')} />
			<rect x="13" y="20" width="13" height="2" rx="1" fill={token('color.link')} />
			<rect
				x="6"
				y="2"
				width="23"
				height="2"
				rx="1"
				fill={token('color.background.accent.gray.subtlest.pressed')}
			/>
			<rect
				x="6"
				y="28"
				width="23"
				height="2"
				rx="1"
				fill={token('color.background.accent.gray.subtlest.pressed')}
			/>
			<path
				d="M3 3.5L2 2.5"
				stroke={token('color.background.accent.gray.subtlest.pressed')}
				strokeLinecap="round"
			/>
			<path
				d="M3 3.5L4 2.5"
				stroke={token('color.background.accent.gray.subtlest.pressed')}
				strokeLinecap="round"
			/>
			<path
				d="M3.5 29L2.5 30"
				stroke={token('color.background.accent.gray.subtlest.pressed')}
				strokeLinecap="round"
			/>
			<path
				d="M3.5 29L2.5 28"
				stroke={token('color.background.accent.gray.subtlest.pressed')}
				strokeLinecap="round"
			/>
		</svg>
	</SVG>
);

// Code taken from https://github.com/podefr/react-debounce-render/tree/master aka react-debounce-render package
// In order to prevent a new package from being added to the AFM project, the code was copied and pasted here since it is lightweight.
import type { ComponentType } from 'react';
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
// eslint-disable-next-line no-restricted-imports
import type { DebounceSettings } from 'lodash';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const debounceRender = <T,>(
	ComponentToDebounce: ComponentType<T>,
	wait?: number,
	debounceArgs?: DebounceSettings,
): ComponentType<T> => {
	class DebouncedContainer extends Component<T> {
		public static readonly displayName = `debounceRender(${ComponentToDebounce.displayName || ComponentToDebounce.name || 'Component'})`;
		// eslint-disable-next-line react/sort-comp
		updateDebounced = debounce(this.forceUpdate, wait, debounceArgs);

		shouldComponentUpdate() {
			this.updateDebounced();
			return false;
		}

		componentWillUnmount() {
			this.updateDebounced.cancel();
		}

		render() {
			return <ComponentToDebounce {...this.props} />;
		}
	}

	return hoistNonReactStatics(DebouncedContainer, ComponentToDebounce);
};

import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineSkeleton = styled.span({
	display: 'inline-block',
	backgroundColor: token('color.skeleton'),
	borderRadius: '5px',
	minWidth: '50px',
	height: '35px',
	padding: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExtensionSkeleton = styled.div({
	display: 'block',
	backgroundColor: token('color.skeleton'),
	borderRadius: '5px',
	width: '100%',
	minWidth: '60px',
	height: '35px',
	padding: token('space.050'),
	boxSizing: 'border-box',
});

export const LegacyMacroRendererShadow: FC<{ showSpinner?: boolean; node: any }> = ({
	showSpinner,
	node,
}) => {
	return node && node.type === 'inlineExtension' ? (
		<InlineSkeleton data-testid="ext-skeleton">
			{showSpinner && <Spinner size="small" />}
		</InlineSkeleton>
	) : (
		<ExtensionSkeleton data-testid="ext-skeleton">
			{showSpinner && <Spinner size="small" />}
		</ExtensionSkeleton>
	);
};

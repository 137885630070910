import type { MacroMetadata } from '../extensions-common';

// This blocklist should reflect what is in https://pug.jira-dev.com/wiki/spaces/AEC/pages/3239608672/Macro+blocklist so if you want to add or remove something, please remember to update the page.

const blocklist = {
	align: true,
	bgcolor: true,
	center: true,
	cheese: true,
	clickable: true,
	code: true,
	colgroup: true,
	column: true,
	'content-by-user': true,
	copyright: true,
	'create-space-button': true,
	div: true,
	expand: true,
	'fancy-bullets': true,
	'google-drive-docs': true,
	'google-drive-sheets': true,
	'google-drive-slides': true,
	favpages: true,
	'gadget-b7a6531b1c147f2e175f1209bed5c0ad': true, // confluence-news-gadget
	'gadget-01c2ed272e88f7333da124a03a481477': true, // wallboard-spacer-gadget MD5 ID
	'gadget-d2450e06a9a285cf57eedb2d502d9d65b964eb847382938b6c57809bac2261bb': true, // wallboard-spacer-gadget SHA256 ID
	'global-reports': true,
	highlight: true,
	htmlcomment: true,
	im: true,
	img: true,
	info: true,
	junitreport: true,
	li: true,
	loremipsum: true,
	lozenge: true,
	multimedia: true,
	navmap: true,
	network: true,
	noformat: true,
	note: true,
	ol: true,
	OneDrive: true,
	p: true,
	panel: true,
	pre: true,
	'privacy-policy': true,
	'privacy-mark': true,
	'recently-used-labels': true,
	'reg-tm': true,
	rollover: true,
	roundrect: true,
	search: true,
	section: true,
	sm: true,
	'space-attachments': true,
	'space-details': true,
	span: true,
	status: true,
	strike: true,
	style: true,
	table: true,
	tbody: true,
	td: true,
	th: true,
	thead: true,
	tr: true,
	'trello-board': true, // Trello macros are now deprecated due to new Trello smart links
	'trello-card': true,
	tip: true,
	tm: true,
	ul: true,
	warning: true,
};

/**
 * Checks whether a given macro is blocklisted (and therefore, should be filtered out).
 * @param macroMetadata - The macro to check
 * @param blocklistOverrides - Optional blocklist overrides. This is a map of macro keys to booleans, where true means blocklisted.
 */
export function isBlocklisted(
	macroMetadata: MacroMetadata,
	blocklistOverrides: { [key: string]: boolean } = {},
): boolean {
	const key =
		macroMetadata.alternateId ||
		(macroMetadata.id ? macroMetadata.id.replace('macro-', '') : macroMetadata.macroName);

	return blocklistOverrides.hasOwnProperty(key) ? blocklistOverrides[key] : Boolean(blocklist[key]);
}

/**
 * Returns a function which checks whether a given macro
 * is allowed based on the blocklist.
 * NOTE: featureFlagClient would need to be passed in if we had any FF's needed to determine blocklist but currently
 * none are needed.
 */
export function isAllowedWithFeatureFlags() {
	const overrides: { [key: string]: boolean } = {};

	return (macroMetadata: MacroMetadata) => !isBlocklisted(macroMetadata, overrides);
}

/**
 * We don't want to show macros that are implemented as native in the Fabric Editor.
 * For now we are removing it in the client side but after Fabric Editor takes over, we should
 * remove them.
 * This code is handling internals of the macro browser. To understand more, check
 * confluence-plugins/confluence-editor-plugins/confluence-macro-browser/js/macro-browser-model.js
 * in core.
 * @param macroBrowser - the instance of macroBrowser
 */
export async function applyBlocklist(macroBrowser): Promise<() => void> {
	const originalList = [...macroBrowser.metadataList];
	// Currently no FF info is needed to create the blocklist but if it was, need to pass featureFlagClient to isAllowedWithFeatureFlags()
	const isAllowed = isAllowedWithFeatureFlags();
	macroBrowser.metadataList = macroBrowser.metadataList.filter(isAllowed);

	/**
	 * Bring blocklisted items back so they show up elsewhere
	 */
	return function restore() {
		macroBrowser.metadataList = originalList;
	};
}

import type { IntlShape } from 'react-intl-next';

import type { FieldDefinition, DynamicFieldDefinitions } from '@atlaskit/editor-common/extensions';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import type { FeatureFlagsType } from '@confluence/session-data';

import type {
	ConfluencePageContext,
	LegacyMacroManifest,
	LegacyParameter,
} from '../extensionTypes';
import { MODERENIZED_MACRO_KEYS } from '../../../extensionConstants';

import type { FieldTransformer } from './types';
import { always } from './always/index';
import { anchor } from './anchor';
import { attachments } from './attachments';
import { blogPosts } from './blog-posts';
import { contributors } from './contributors';
import { include } from './include';
import { officeConnector } from './office-connector';
import { tasksReport } from './tasks-report';
import { pagetree } from './pagetree';
import { calendar } from './calendar';
import { recentlyUpdated } from './recently-updated/recently-updated';
import { recentlyUpdatedDashboard } from './recently-updated-dashboard';
import { createFromTemplate } from './create-from-template';
import { children } from './children/children';
import { excerpt } from './excerpt';

const makeObject = (xs: LegacyParameter[]): Record<string, LegacyParameter> =>
	xs.reduce((obj, x) => {
		obj[x.name] = x;
		return obj;
	}, {});

// Function to infer type
const createMacroMap = <T extends {}>(map: Record<string, FieldTransformer<T>>) => map;

export const getMacroMap = <T extends {}>(): Record<string, FieldTransformer<T>> => {
	const macroMap = createMacroMap({
		attachments,
		'blog-posts': blogPosts,
		calendar,
		children,
		contributors,
		'contributors-summary': contributors,
		'create-from-template': createFromTemplate,
		excerpt,
		include,
		pagetree,
		viewpdf: officeConnector,
		viewdoc: officeConnector,
		viewxls: officeConnector,
		viewppt: officeConnector,
		'tasks-report-macro': tasksReport,
		anchor,
		'recently-updated': recentlyUpdated,
		'recently-updated-dashboard': recentlyUpdatedDashboard,
	});

	return macroMap;
};

// This provides a hook to modify automatically created FieldDefinition(s)
// eg. When we need to make a select multi select, or force a new type
export const remapCustomFields = async (
	{ macroName }: LegacyMacroManifest,
	fields: FieldDefinition[],
	pageContext: ConfluencePageContext,
	intl: IntlShape,
	params?: any,
	featureFlags?: FeatureFlagsType,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	isLivePage?: boolean,
): Promise<FieldDefinition[] | DynamicFieldDefinitions<{}>> => {
	const transformFields = getMacroMap()[macroName]?.transformFields;
	const alwaysFn = always?.transformFields;

	if (macroName === 'create-from-template') {
		return transformFields
			? await transformFields(
					fields,
					pageContext,
					intl,
					params,
					createAnalyticsEvent,
					featureFlags,
					isLivePage,
				)
			: fields;
	} else {
		let newFields;
		if (transformFields) {
			try {
				newFields = await transformFields(
					fields,
					pageContext,
					intl,
					params,
					createAnalyticsEvent,
					featureFlags,
					isLivePage,
				);
			} catch (error) {
				if (MODERENIZED_MACRO_KEYS.includes(macroName)) {
					fireErrorAnalytics(macroName, 'transformFields', error, createAnalyticsEvent);
				}
			}
		} else newFields = fields;

		return alwaysFn ? alwaysFn(newFields, pageContext, intl) : newFields;
	}
};

/**
 * Returns one of the transform functions that should be passed into the extension node's actions.editInContextPanel function.
 * See: https://product-fabric.atlassian.net/wiki/spaces/E/pages/1051070113/Nodes
 * @param transformType specifies the type of transformation and applies any specific macro transforms.
 * This can be one of two transformation types:
 * 1. transformBefore: triggered immediately before a parameter update
 *    - Useful for transforming macro parameters beyond their metadata form definition
 *    - eg. for task report macro we store { pages: '123', spaces: '~456' } in macroParams
 *      but its form definition has one spaceAndPage field containing both pages and spaces
 *      field values joined together
 * 2. transformAfter: triggered immediately after a parameter update
 *    - Generally useful to transform params back to their metadata form definition
 * @returns the transformed parameters
 */
export const transformParams = (
	transformType: 'transformBefore' | 'transformAfter',
	macro: LegacyMacroManifest,
	params: any,
	pageContext?: ConfluencePageContext,
	featureFlags?: FeatureFlagsType,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	const { macroName, formDetails } = macro;
	const metadata = makeObject(formDetails.parameters);
	const transformFn = getMacroMap()[macroName]?.[transformType];
	const alwaysFn = always?.[transformType];
	let newParams;
	if (transformFn) {
		try {
			newParams = transformFn(params, metadata, pageContext, featureFlags);
		} catch (error) {
			if (MODERENIZED_MACRO_KEYS.includes(macroName)) {
				fireErrorAnalytics(macroName, transformType, error, createAnalyticsEvent);
			}
		}
	} else newParams = params;

	return alwaysFn ? alwaysFn(newParams, metadata, pageContext) : newParams;
};

const fireErrorAnalytics = (
	macroName: string,
	fn: string,
	error: Error,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	if (createAnalyticsEvent) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'error',
				actionSubject: fn,
				actionSubjectId: macroName,
				source: 'field-mappings',
				attributes: {
					error: error.message,
					stack: error.stack,
				},
			},
		}).fire();
	}
};

import React from 'react';

import SVG from '@atlaskit/icon/svg';
import type { IconProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

export const ExcerptMacroIcon = (props: IconProps) => (
	<SVG size="large" secondaryColor={token('color.background.accent.gray.subtler')} {...props}>
		{/* Scaling the svg to 24x24 to match the design system. Keeping the viewBox at 32x32 to keep the proportions of the original icon. */}
		<svg
			width="24"
			height="24"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="excerpt-macro-icon"
		>
			<rect
				x="5"
				y="8"
				width="22"
				height="16"
				rx="2"
				fill={token('color.background.accent.blue.subtlest.hovered')}
			/>
			<rect x="8" y="15" width="16" height="2" rx="1" fill={token('color.link')} />
			<rect x="8" y="11" width="16" height="2" rx="1" fill={token('color.link')} />
			<rect
				x="8"
				y="4"
				width="19"
				height="2"
				rx="1"
				fill={token('color.background.accent.gray.subtlest.pressed')}
			/>
			<rect
				x="8"
				y="30"
				width="19"
				height="2"
				rx="1"
				fill={token('color.background.accent.gray.subtlest.pressed')}
			/>
			<rect
				x="8"
				width="16"
				height="2"
				rx="1"
				fill={token('color.background.accent.gray.subtlest.pressed')}
			/>
			<rect
				x="8"
				y="26"
				width="16"
				height="2"
				rx="1"
				fill={token('color.background.accent.gray.subtlest.pressed')}
			/>
			<rect x="8" y="19" width="10" height="2" rx="1" fill={token('color.link')} />
		</svg>
	</SVG>
);

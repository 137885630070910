import { token } from '@atlaskit/tokens';

export const panel = {
	display: 'block', // DEBUGGING
	borderWidth: '1px',
	borderRadius: '3px',
	borderStyle: 'solid',
	borderColor: token('color.border'),
	borderImage: 'initial',
};

export const panelHeader = {
	display: 'block', // DEBUGGING
	backgroundColor: token('color.background.accent.gray.subtlest'),
	minWidth: '128px',
	padding: token('space.100'),
	fontSize: '16px',
};

export const panelBody = {
	display: 'block', // DEBUGGING
	minWidth: '128px',
	borderWidth: '1px 0px 0px 0px',
	borderStyle: 'solid',
	borderColor: token('color.border'),
	padding: token('space.100'),
};

export const panelHeaderExcerpt = {
	color: token('color.text.subtlest'),
	paddingLeft: token('space.050'),
};

import type { ComponentProps } from 'react';
import React from 'react';
import type { Mark } from '@atlaskit/editor-prosemirror/model';
import type { EditorProps } from '@atlaskit/editor-core';
import { Extension } from '@atlaskit/editor-common/extensibility';
import type { ReferenceEntity } from '@atlaskit/editor-common/extensions';
import type { DataSourceProvider } from '../../data-source-provider';

export type Props = ComponentProps<typeof Extension> & {
	dataSourceProvider?: DataSourceProvider;
	editorAppearance?: NonNullable<EditorProps['appearance']>;
};

export const useExtensionWithDataSource = ({
	editorView,
	node,
	dataSourceProvider,
}: Props): ReferenceEntity[] => {
	const dataSourceId = node.marks?.find(
		(mark: Mark) => mark.type === editorView.state.schema.marks?.dataConsumer,
	)?.attrs?.sources?.[0];

	const [reference, setReference] = React.useState<ReferenceEntity | undefined>(() => {
		const dataSourceReference = dataSourceProvider?.get(dataSourceId);
		if (dataSourceReference) {
			return dataSourceReference;
		}
	});

	React.useEffect(() => {
		dataSourceProvider?.unsubscribe(dataSourceId, setReference);
		dataSourceProvider?.subscribe(dataSourceId, setReference);
		return () => dataSourceProvider?.unsubscribe(dataSourceId, setReference);
	}, [dataSourceProvider, dataSourceId]);
	return reference ? [reference] : [];
};

const ExtensionWithDataSource = (props: React.PropsWithChildren<Props>) => {
	const reference = useExtensionWithDataSource(props);
	// Ignored via go/ees005
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Extension {...props} references={reference} />;
};

export default ExtensionWithDataSource;

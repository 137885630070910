import { useEffect, useMemo } from 'react';

import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

const canOpenInNewTab = (href: string): boolean => {
	if (!href) {
		// e.g., "Refresh" link in jira issue macro
		return false;
	}

	const url = new URL(href);

	if (url.origin !== window.location.origin) {
		return true;
	} else if (url.pathname.includes('/wiki/rest/api')) {
		return false;
	} else if (url.pathname.includes('changes.action')) {
		// e.g., "Show More" link in the recently updated macro
		return false;
	} else if (url.pathname.includes('confirmattachmentremoval.action')) {
		// e.g., "Delete" link in the attachments macro
		return false;
	} else if (url.pathname.includes('.action')) {
		// Most .action links should open in a new tab
		return true;
	} else {
		return true;
	}
};

export const useLinkIntercept = (
	extensionKey: string,
	container: Element | null,
	isEditor: boolean,
) => {
	const extensionsToOpenLinksInNewTab: Set<string> = useMemo(() => {
		const extensionSet = new Set<string>();

		if (!isEditor) {
			return extensionSet;
		}

		const allowlist = StatsigConfigurations.getStringList(
			'confluence_frontend_macro_links_new_tab_allowlist',
			[],
		);

		allowlist.map((ext) => ext.trim().toLowerCase()).forEach((ext) => ext && extensionSet.add(ext));

		return extensionSet;
	}, [isEditor]);

	useEffect(() => {
		// Short circuit if we're not in the editor or the container is falsey
		if (!isEditor || !container) {
			return;
		}

		// Only intercept links in the FF allowlist
		if (!extensionsToOpenLinksInNewTab?.has(extensionKey.toLowerCase())) {
			return;
		}

		function interceptLinkClick(ev: Event) {
			const anchor = ev?.target as HTMLAnchorElement;

			// Only intercept clicks on anchor tags
			if (anchor?.tagName !== 'A') {
				return;
			}

			if (!canOpenInNewTab(anchor.href)) {
				return;
			}

			ev.preventDefault();
			window.open(anchor.href, '_blank', 'noopener, noreferrer');
		}

		container.addEventListener('click', interceptLinkClick);

		return () => {
			// Remove the event listener when the component unmounts
			container.removeEventListener('click', interceptLinkClick);
		};
	}, [container, extensionsToOpenLinksInNewTab, extensionKey, isEditor]);
};

import type { IntlShape } from 'react-intl-next';

import type { CustomField, FieldDefinition } from '@atlaskit/editor-common/extensions';

import { fg } from '@confluence/feature-gating';

import { parseBoolean } from '../utils';

import { i18n, i18nTheme, i18nTypes } from './i18n';

export const macroFields = (
	name: string,
	fields: { [key: string]: FieldDefinition },
	intl: IntlShape,
	_defaultValue?: any,
	isLivePage?: boolean,
) => {
	let field;
	switch (name) {
		case 'spaces':
			field = {
				...getSpacesField(fields['spaces']),
				label: intl.formatMessage(i18n.spaceLabel),
				description: '',
				placeholder: intl.formatMessage(i18n.spacePlaceholder),
			};
			break;
		case 'types':
			field = {
				...fields['types'],
				label: intl.formatMessage(i18n.typesLabel),
				description: '',
				placeholder: intl.formatMessage(i18n.typePlaceholder),
				type: 'enum',
				style: 'select',
				isMultiple: true,
				items: [
					{
						label: intl.formatMessage(i18nTypes.allLabel),
						value: '',
					},
					{
						label:
							isLivePage && fg('confluence_live_pages_open_beta_trait_opted_in')
								? intl.formatMessage(i18nTypes.livePageLabel)
								: intl.formatMessage(i18nTypes.pageLabel),
						value: 'page',
					},
					{
						label: intl.formatMessage(i18nTypes.blogPostLabel),
						value: 'blogpost',
					},
					{
						label: intl.formatMessage(i18nTypes.commentLabel),
						value: 'comment',
					},
					{
						label: intl.formatMessage(i18nTypes.attachmentLabel),
						value: 'attachment',
					},
				],
			};
			break;
		case 'maxToggle':
			field = {
				name: 'maxToggle',
				type: 'boolean',
				style: 'toggle',
				label: intl.formatMessage(i18n.maxToggleLabel),
				description: '',
				defaultValue: false,
				isHidden: false,
				isMultiple: false,
				isRequired: false,
			};
			break;
		case 'max':
			field = {
				...fields['max'],
				label: intl.formatMessage(i18n.maxLabel),
			};
			break;
		case 'theme':
			field = {
				...fields['theme'],
				label: intl.formatMessage(i18n.themeLabel),
				placeholder: intl.formatMessage(i18nTheme.conciseLabel),
				items: [
					{
						label: intl.formatMessage(i18nTheme.conciseLabel),
						value: 'concise',
					},
					{
						label: intl.formatMessage(i18nTheme.sidebarLabel),
						value: 'sidebar',
					},
					{
						label: intl.formatMessage(i18nTheme.socialLabel),
						value: 'social',
					},
				],
			};
			break;
		case 'author':
			field = {
				...fields['author'],
				label: intl.formatMessage(i18n.authorLabel),
				placeholder: intl.formatMessage(i18n.authorPlaceholder),
				description: '',
			};
			break;
		case 'labels':
			field = {
				...fields['labels'],
				label: intl.formatMessage(i18n.labelsLabel),
				placeholder: intl.formatMessage(i18n.labelsPlaceholder),
				description: '',
				isMultiple: true,
				type: 'custom',
				options: {
					resolver: {
						type: 'label',
					},
				},
			};
			break;
		case 'width':
			field = {
				...fields['width'],
				label: intl.formatMessage(i18n.widthLabel),
				placeholder: intl.formatMessage(i18n.widthPlaceholder),
				description: '',
				type: 'custom',
				options: {
					isCreatable: true,
					formatCreateLabel: (value: string) => {
						return value;
					},
					resolver: {
						type: 'widthPercentage',
					},
				},
			};
			break;
		case 'hideHeading':
			field = {
				...fields['hideHeading'],
				label: intl.formatMessage(i18n.hideHeadingLabel),
			};
			break;
	}
	return field as FieldDefinition;
};

const getSpacesField = (field: FieldDefinition) => {
	return {
		...field,
		isMultiple: true,
		type: 'custom',
		options: {
			resolver: {
				type: 'blogRecentlyUpdatedSpace',
			},
		},
	} as CustomField;
};

export const getBasicTabFields = (
	name: string,
	fields: { [key: string]: FieldDefinition },
	intl: IntlShape,
	currentParams?,
	isLivePage?: boolean,
): FieldDefinition | Array<FieldDefinition> => {
	if (name === 'max') {
		const result: Array<FieldDefinition> = [];
		result.push(macroFields('maxToggle', fields, intl, isLivePage));
		if (!parseBoolean(currentParams?.macroParams?.maxToggle?.value)) {
			result.push(macroFields(name, fields, intl));
		}
		return result;
	}
	return macroFields(name, fields, intl, isLivePage);
};

import type {
	DynamicFieldDefinitions,
	FieldDefinition,
	TabGroupField,
} from '@atlaskit/editor-common/extensions';

import type { FieldTransformer } from '../types';
import { parseBoolean } from '../utils';

import { getBasicTabFields, macroFields } from './fields';
import { i18n } from './i18n';

interface RecentlyUpdatedMacroParams {
	spaces?: string;
	types?: string[] | string;
	max?: number | string;
	maxToggle?: boolean | string;
	theme?: string;
	labels?: string[] | string;
	showProfilePic?: boolean | string;
}

const basicTabFields: Array<string> = ['spaces', 'types', 'max'];
const displayTabFields: Array<string> = ['theme', 'author', 'labels', 'width', 'hideHeading'];

export const recentlyUpdated: FieldTransformer<RecentlyUpdatedMacroParams> = {
	transformFields: async (
		fields,
		_pageContext,
		intl,
		_params,
		createAnalyticsEvent,
		_featureFlags,
		isLivePage,
	): Promise<DynamicFieldDefinitions<{}>> => {
		const fireErrorAnalytics = (tab, error) => {
			if (createAnalyticsEvent) {
				createAnalyticsEvent({
					type: 'sendOperationalEvent',
					data: {
						action: 'error',
						actionSubject: 'transformFields',
						actionSubjectId: tab,
						source: 'field-mappings/recently-updated',
						attributes: {
							error: error.message,
							stack: error.stack,
						},
					},
				}).fire();
			}
		};

		const getDynamicFields: DynamicFieldDefinitions<{}> = (currentParams?) => {
			const fieldsObj: { [key: string]: FieldDefinition } = fields.reduce((obj, field) => {
				obj[field.name] = field;
				return obj;
			}, {});

			return [
				{
					type: 'tab-group',
					name: 'tabGroup',
					label: 'Recent Updates Tab Group',
					defaultTab: 'basic',
					fields: [
						{
							type: 'tab',
							label: intl.formatMessage(i18n.basicTabLabel),
							name: 'basic',
							fields: [
								...basicTabFields.flatMap((fieldName) => {
									try {
										return getBasicTabFields(fieldName, fieldsObj, intl, currentParams, isLivePage);
									} catch (error) {
										fireErrorAnalytics('basicTab', error);
										return [];
									}
								}),
							],
						},
						{
							type: 'tab',
							label: intl.formatMessage(i18n.displayTabLabel),
							name: 'display',
							fields: [
								...displayTabFields.map((fieldName) => {
									try {
										return macroFields(fieldName, fieldsObj, intl);
									} catch (error) {
										fireErrorAnalytics('displayTab', error);
										return [];
									}
								}),
							],
						},
					],
				} as TabGroupField,
			] as FieldDefinition[];
		};
		return getDynamicFields;
	},

	transformBefore(params, _metadata, _pageContext) {
		// Convert "Include in list" string to array for FE enum field
		if (params.types) {
			params.types = (params.types as string).split(/[ ,]+/);
		} else {
			params.types = [''];
		}

		// Convert labels param from string to array for FE custom field
		if (params.labels) {
			params.labels = (params.labels as string).split(/[ ,]+/);
		}

		// We are hiding "Show User Profile Pictures" checkbox
		// In the BE if this was checked, it sets theme to "social" and overrides the "theme" param
		// So if an existing macro had this checked, set theme to "social" (results in no change to macro view)
		if (parseBoolean(params.showProfilePic)) {
			params.theme = 'social';
			params.showProfilePic = 'false';
		}

		return params;
	},

	transformAfter(params, _metadata, _pageContext) {
		// "Include in list" param behavior: Default is "All" which is mutually exclusive with other options.
		// i.e. selection in this field can be either "All" by itself, or any content types (but no "All").
		// Also converts array to string for BE field data type
		if (params.types) {
			const types = params.types as string[];
			if (types[types.length - 1] === '') {
				// "All" was just chosen, remove all other selections
				params.types = '';
			} else if (types.some((type) => type === '')) {
				// A content type was selected but "All" exists in the list, so remove "All"
				types.splice(types.indexOf(''), 1);
				params.types = types.join();
			} else {
				params.types = types.join();
			}
		}

		// If "Show all updates" toggle is on, set max param to highest allowed from BE
		const showAll = parseBoolean(params.maxToggle);
		if (showAll) {
			params.max = 250;
		}

		// Convert labels param from array to string for BE field definition
		if (params.labels) {
			params.labels = (params.labels as string[]).join();
		}

		return params;
	},
};

import type { VFC } from 'react';
import React, { useMemo } from 'react';

import { ExcerptIncludeLoader } from '@confluence/excerpt-include/entry-points/loader';
import type {
	RendererWrapperProps,
	ExcerptIncludeProps,
} from '@confluence/excerpt-include/entry-points/types';
import { MustPreloadLoadableNames, mustPreloadWhenRenderingSPA } from '@confluence/loadable';
import type {
	ExtensionHandlerProps,
	RendererExtensionHandlers,
} from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';

type ExcerptIncludeExtensionHandlerProps = ExtensionHandlerProps & {
	appearance?: string;
	Renderer: VFC<any>;
	getExtensionHandlers: (options: any) => RendererExtensionHandlers;
};

export const ExcerptIncludeSSRExtensionHandler: VFC<ExcerptIncludeExtensionHandlerProps> = ({
	contentId,
	extensionKey,
	mode,
	node,
	spaceKey,
	appearance,
	showTemplateVariablesInPreview,
	showTemplateInputInPreview,
	Renderer,
	getExtensionHandlers,
}) => {
	mustPreloadWhenRenderingSPA([
		MustPreloadLoadableNames.ClassicRendererLoader,
		MustPreloadLoadableNames.ClassicEditorContextProviderLoader,
	]);

	const RendererWrapper = useMemo(
		() =>
			({ targetContentId, adf, mediaToken }: RendererWrapperProps) => {
				const document = { type: 'doc', version: 1, content: adf };

				// ADFRenderer (next-based) requires extension handlers during rendering on server side,
				// for extensions like this as they use renderer internally.
				const extensionHandlers = getExtensionHandlers({
					adf: document,
					contentId: targetContentId,
					appearance,
					showTemplateInputInPreview,
					showTemplateVariablesInPreview,
				});

				return (
					<Renderer
						contentId={targetContentId}
						document={document}
						hasInlineComments={false}
						showTemplateInputInPreview={showTemplateInputInPreview}
						showTemplateVariablesInPreview={showTemplateVariablesInPreview}
						extensionHandlers={extensionHandlers}
						appearance={appearance || 'default'}
						spaceKey={spaceKey}
						mediaToken={mediaToken}
					/>
				);
			},
		[
			getExtensionHandlers,
			appearance,
			showTemplateInputInPreview,
			showTemplateVariablesInPreview,
			spaceKey,
			Renderer,
		],
	);

	const componentProps: ExcerptIncludeProps = {
		mode,
		contentId,
		spaceKey,
		extensionKey,
		macroNode: node,
		RendererWrapper,
		isLegacyRenderer: false,
	};
	return <ExcerptIncludeLoader {...componentProps} />;
};

import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

export function getMultiMacroQueryBlocklistFF() {
	const blocklist = StatsigConfigurations.getStringList(
		'confluence_frontend_macros_query_blocklist',
		[
			'include',
			'jirachart',
			'jiraroadmap',
			'ui-button',
			'contentbylabel',
			'excerpt-include',
			'tasks-report-macro',
			'blog-posts',
			'section',
			'table-filter',
			'children',
			'pagetree',
			'detailssummary',
			'decisionreport',
		],
	);

	return String(blocklist).split(',');
}

export function canUseMultiMacroQuery(
	extensionKey: string | undefined,
	isNested2pBodiedExtension = false,
) {
	return (
		!isNested2pBodiedExtension && !getMultiMacroQueryBlocklistFF().includes(extensionKey || '')
	);
}

import React from 'react';

import { JiraIcon as AkJiraIcon } from '@atlaskit/logo';
import AlignTextLeftIcon from '@atlaskit/icon/core/align-text-left';
import DefaultEditorAddonIcon from '@atlaskit/icon/core/app';
import RecentIcon from '@atlaskit/icon/core/clock';
import LabelIcon from '@atlaskit/icon/core/tag';
import UserAvatarCircleIcon from '@atlaskit/icon/core/person-avatar';
import PersonIcon from '@atlaskit/icon/core/person';
import LinkIcon from '@atlaskit/icon/core/link';
import { token } from '@atlaskit/tokens';

import { ChildMacroIcon } from '@confluence/icons/entry-points/ChildMacroIcon';
import { ExcerptIncludeMacroIcon } from '@confluence/icons/entry-points/ExcerptIncludeMacroIcon';
import { ExcerptMacroIcon } from '@confluence/icons/entry-points/ExcerptMacroIcon';
import { getContextAwareFullPath } from '@confluence/route-manager/entry-points/getContextAwareFullPath';

import type { MacroMetadata } from '../../extensions-common';

import { isProfileMacrosExperimentEnabled } from './utils';

type Size = 'small' | 'medium' | 'large';
type Props = {
	macro: MacroMetadata;
	size?: Size;
	testId?: string;
};

const sizeMap = {
	small: 24,
	medium: 32,
	large: 48,
};

const getSizeInPx = (size: Size) => sizeMap[size];

export function MacroIcon({ macro, size = 'small', testId }: Props) {
	const { icon, macroName } = macro;

	switch (macroName) {
		case 'children':
			return <ChildMacroIcon label="" />;
		case 'excerpt':
			return <ExcerptMacroIcon label="" />;
		case 'excerpt-include':
			return <ExcerptIncludeMacroIcon label="" />;
		case 'recently-updated':
			return (
				<RecentIcon
					color={token('color.icon')}
					label=""
					spacing="spacious"
					testId="recently-updated-macro-icon"
				/>
			);
		case 'contentbylabel':
			return (
				<LabelIcon
					color={token('color.icon')}
					label=""
					spacing="spacious"
					testId="contentbylabel-macro-icon"
				/>
			);
		case 'profile-picture':
			if (isProfileMacrosExperimentEnabled()) {
				return <UserAvatarCircleIcon label="" spacing="spacious" testId="profile-picture-icon" />;
			}
			break;
		case 'profile':
			if (isProfileMacrosExperimentEnabled()) {
				return <PersonIcon label="" spacing="spacious" testId="profile-icon" />;
			}
			break;
		case 'toc':
			return <AlignTextLeftIcon label="" testId="toc-icon" spacing="spacious" />;

		case 'jira':
			return <AkJiraIcon label="" size="medium" data-testid={testId} />;

		case 'anchor':
			return <LinkIcon label="" spacing="spacious" testId="anchor-link-icon" />;
	}

	if (!icon) {
		return <DefaultEditorAddonIcon label="" spacing="spacious" testId="default-icon" />;
	}

	// connect macros will have absolute urls while others
	// will have relative
	const src = getContextAwareFullPath(icon.location, true);
	const sizeInPx = getSizeInPx(size);
	return <img src={src} width={sizeInPx} height={sizeInPx} alt="" data-testid={testId} />;
}

export const BulletSVG = () => (
	<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="4" cy="4" r="4" fill={token('color.icon')} />
	</svg>
);

export const CircleSVG = () => (
	<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="4" cy="4" r="3.5" stroke={token('color.icon')} />
	</svg>
);

// also known as mixed as in it will have different bullet styles for each level
export const DiscSVG = () => (
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z"
			fill={token('color.icon')}
		/>
		<path
			d="M9 13.9V16C9 16.5523 9.44772 17 10 17H15C15.5523 17 16 16.5523 16 16V11C16 10.4477 15.5523 10 15 10H12.9C12.5023 11.9591 10.9591 13.5023 9 13.9Z"
			fill={token('color.icon')}
		/>
		<path
			d="M4 8.99997C4 11.2091 5.79086 13 8 13C10.2091 13 12 11.2091 12 8.99997C12 7.09292 10.6654 5.49757 8.87929 5.09692C8.80495 5.42907 8.69755 5.74873 8.56074 6.05229C9.9496 6.31488 11 7.53476 11 8.99997C11 10.6568 9.65685 12 8 12C6.34315 12 5 10.6568 5 8.99997C5 8.96639 5.00055 8.93293 5.00165 8.89962C4.67803 8.96542 4.34305 8.99997 4 8.99997Z"
			fill={token('color.icon')}
		/>
	</svg>
);

export const NumberedSVG = () => (
	<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.41016 10H4.64062V0.135742H3.41699L0.791992 2.02246V3.32129L3.30078 1.50293H3.41016V10ZM8.22266 10.0684C8.71484 10.0684 9.11133 9.66504 9.11133 9.17969C9.11133 8.6875 8.71484 8.29102 8.22266 8.29102C7.7373 8.29102 7.33398 8.6875 7.33398 9.17969C7.33398 9.66504 7.7373 10.0684 8.22266 10.0684Z"
			fill={token('color.icon')}
		/>
	</svg>
);

export const SquareSVG = () => (
	<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="8" height="8" rx="1" fill={token('color.icon')} />
	</svg>
);

export const BraceSVG = () => (
	<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.80648 7.77764V9.55542C1.80648 10.2037 2.06826 10.8254 2.53401 11.2837C2.99977 11.7423 3.63154 11.9997 4.2904 11.9997H4.96785V10.6665H4.2904C3.99098 10.6665 3.70384 10.5493 3.49198 10.341C3.28029 10.1327 3.16137 9.85009 3.16137 9.55541V7.77764C3.16011 7.10412 2.87676 6.46084 2.37775 5.99986C2.87674 5.53888 3.16008 4.8956 3.16137 4.22209V2.44431C3.16137 2.14964 3.28029 1.86706 3.49198 1.65873C3.70383 1.45041 3.99097 1.33322 4.2904 1.33322H4.96785V0H4.2904C3.63154 0 2.99977 0.257466 2.53401 0.715975C2.06825 1.17434 1.80648 1.79607 1.80648 2.44431V4.22208C1.80648 4.51675 1.68756 4.79933 1.47587 5.00782C1.26402 5.21615 0.976884 5.33318 0.677452 5.33318H0V6.66656H0.677452C0.976876 6.66656 1.26402 6.78359 1.47587 6.99191C1.68756 7.2004 1.80648 7.48297 1.80648 7.77764Z"
			fill={token('color.icon')}
		/>
		<path
			d="M12.1936 4.2222V2.44443C12.1936 1.79619 11.9318 1.17446 11.4661 0.716097C11.0003 0.257576 10.3685 0.00012207 9.70968 0.00012207H9.03223V1.3335H9.70968V1.33335C10.0091 1.33335 10.2962 1.45053 10.5081 1.65886C10.7198 1.86719 10.8387 2.14976 10.8387 2.44444V4.22221C10.84 4.89573 11.1233 5.53901 11.6223 5.99999C11.1233 6.46097 10.84 7.10425 10.8387 7.77776V9.55554C10.8387 9.85021 10.7198 10.1328 10.5081 10.3411C10.2962 10.5494 10.0091 10.6666 9.70968 10.6666H9.03223V12H9.70968V11.9999C10.3685 11.9999 11.0003 11.7424 11.4661 11.2839C11.9318 10.8255 12.1936 10.2038 12.1936 9.55555V7.77777C12.1936 7.4831 12.3125 7.20052 12.5242 6.99204C12.7361 6.78371 13.0232 6.66668 13.3226 6.66668H14.0001V5.3333H13.3226C13.0232 5.3333 12.7361 5.21627 12.5242 5.00794C12.3125 4.79946 12.1936 4.51688 12.1936 4.2222Z"
			fill={token('color.icon')}
		/>
	</svg>
);

export const BracketSVG = () => (
	<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4 1.5H1V12.5H4" stroke={token('color.icon')} strokeWidth="1.25" />
		<path d="M8 12.5H11L11 1.5H8" stroke={token('color.icon')} strokeWidth="1.25" />
	</svg>
);

export const PipeSVG = () => (
	<svg width="2" height="12" viewBox="0 0 2 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<line
			x1="0.625"
			y1="2.73196e-08"
			x2="0.624999"
			y2="12"
			stroke={token('color.icon')}
			strokeWidth="1.25"
		/>
	</svg>
);

type DropdownProps = {
	icon: () => JSX.Element;
	testId?: string;
};

export function DropdownIcon({ icon, testId }: DropdownProps) {
	return (
		<div
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				width: 16,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				height: 16,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				display: 'flex',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				alignItems: 'center',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				justifyContent: 'center',
			}}
			data-testId={testId}
		>
			{icon()}
		</div>
	);
}

import { token } from '@atlaskit/tokens';

export const loadingBorderRadius = '3px';
export const loadingGrey = token('color.skeleton');

export const iconStyles = `
  box-sizing: border-box;
  margin: 0 !important;
  margin-right: 4px !important;
  padding: 0 !important;
  flex-shrink: 0;
  flex-grow: 0;
  align-self: flex-start;
  box-shadow: none !important;
`;

import type { ReactElement } from 'react';

import type { ExperienceAttributes } from '@confluence/experience-tracker';

import { getMacroExperienceTracker } from '../MacroExperienceTracker';
import type { Mode } from '../index';
import { useDeepCompareEffect } from '../useDeepCompareEffect';

export type MacroExperienceSuccessProps = {
	mode: Mode;
	extensionKey?: string;
	name: string;
	attributes?: ExperienceAttributes;
	contentId: string;
	children?: ReactElement | null;
};

export function MacroExperienceSuccess({
	attributes,
	children = null,
	contentId,
	extensionKey,
	mode,
	name,
}: MacroExperienceSuccessProps) {
	useDeepCompareEffect(() => {
		getMacroExperienceTracker(mode, contentId).succeed({
			name,
			attributes,
		});
	}, [attributes, contentId, extensionKey, mode, name]);

	// https://stackoverflow.com/questions/54905376
	return children as ReactElement<any>;
}

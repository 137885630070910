import { useMemo } from 'react';

import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { is1PMacro } from '@confluence/fabric-extension-lib/entry-points/macroClassificationUtils';
import { fg } from '@confluence/feature-gating';
import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

export const useShouldHideOverlay = (extensionKey: string, isBodiedExtension: boolean): boolean => {
	const isLivePage = useIsCurrentPageLive();

	const shouldHide: boolean = useMemo(() => {
		const extensionsToHideOverlays = StatsigConfigurations.getStringList(
			'confluence_frontend_extension_overlay_removal_list',
			[],
		);

		if (!extensionKey || extensionsToHideOverlays.length === 0) {
			return false;
		}

		if (extensionsToHideOverlays[0] === 'ALLOW_ALL' || isLivePage) {
			return true;
		}

		const is2PNonBodied = !is1PMacro(extensionKey) && !isBodiedExtension;
		if (is2PNonBodied && fg('confluence_disable_macro_overlay_for_2p_nonbodied')) {
			return true;
		}

		const extensionKeys = extensionsToHideOverlays.map((ext) => ext.trim().toLowerCase());

		return extensionKeys.includes(extensionKey.toLowerCase());
	}, [extensionKey, isLivePage, isBodiedExtension]);

	return shouldHide;
};

export const is1PMacro = (macro: string) => MACROS_1P.has(macro);

// Non 1P bodied macros are expected to not support the in editor renderer view toggle.
// Add new 1P macros to this list as needed, to avoid rendering the in editor renderer view toggle.
export const MACROS_1P = new Set<string>([
	'toc',
	'children',
	'livesearch',
	'recently-updated',
	'details',
	'jlol',
	'jira',
	'pagetree',
	'anchor',
	'contentbylabel',
	'create-from-template',
	'roadmap',
	'blog-posts',
	'excerpt',
	'attachments',
	'profile',
	'include',
	'content-report-table',
	'detailssummary',
	'tasks-report-macro',
	'excerpt-include',
	'iframe',
	'listlabels',
	'profile-picture',
	'change-history',
	'pagetreesearch',
	'widget',
	'decisionreport',
	'viewpdf',
	'contributors',
	'gadget',
	'popular-labels',
	'jirachart',
	'toc-zone',
	'portfolioforjiraplan',
	'viewppt',
	'viewxls',
	'recently-updated-dashboard',
	'spaces',
	'index',
	'contributors-summary',
	'userlister',
	'gallery',
	'viewdoc',
	'chart',
	'related-labels',
	'jiraroadmap',
]);

import type { VFC } from 'react';
import React, { useEffect, useRef } from 'react';

import { usePageState } from '@confluence/page-context';
import { isInitial } from '@confluence/browser-metrics';
import {
	getPreloadFailures,
	getGeneralFailures,
} from '@confluence/fabric-extension-lib/entry-points/ssr-macro-analytics-helpers';
import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

import { LEGACY_EXTENSION_LOADING_METRIC } from './perf.config';
import { UNKNOWN_INDEX, useExtensionIndex } from './ExtensionIndex';
import { getExtensionSSRMark } from './ExtensionSSRMark';

const NOT_SSRED = 'none';
const NOT_SSRED_BLOCKED = 'ssr-blocked';

// Limit performance tracking for only top 10 extensions on initial load.
// The value is semi-arbitrary, as this is +2 to the number of extensions
// we attempt to SSR on initial load - this will give us an idea how the
// non-SSRed extensions are performing.
// This will lead to ~39% more tracking events send overall, comparing to
// 50/50 rollout we had before, but will prevent from sending huge amount
// of events for individual pages with a lot of extensions.
const TRACKING_LIMIT = 10;

const isBlocklisted = (ssrPreloadFailure?: string) =>
	ssrPreloadFailure?.startsWith('blocklist') ? NOT_SSRED_BLOCKED : NOT_SSRED;

// This check is being made only upon initial render, and therefore is sufficient for
// checking whether element (in our case the marker) appears above the fold when rendered.
const isMarkerAboveTheFold = (el: HTMLSpanElement | null) => {
	if (!el) return false;

	const rect = el.getBoundingClientRect();
	return (
		rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
	);
};

const getPerformanceEntry = (entryName: string) => {
	if (performance && typeof performance.getEntriesByName === 'function') {
		return performance.getEntriesByName(entryName);
	}
	return [];
};

export const ExtensionLoadingComplete: VFC<{
	contentId: string;
	extensionKey: string;
	node: any;
}> = ({ node, contentId, extensionKey }) => {
	const [{ routeName }] = usePageState();
	const { localId } = node;
	const macroId = node.parameters?.macroMetadata?.macroId?.value;

	const extensionBlocklist = StatsigConfigurations.getString(
		'confluence-extensions-server-render-blocklist',
		'',
	);

	const markerRef = useRef<HTMLSpanElement>(null);

	const { extensionIndex, isUnsupportedExtension } = useExtensionIndex(macroId);

	const doNotTrackExtension = extensionIndex === UNKNOWN_INDEX || extensionIndex >= TRACKING_LIMIT;

	useEffect(() => {
		// NOTE: as of now, this metric is tailored to monitoring the performance of extensions
		// on initial load. We're not planning to use this metric in any way on transitions yet
		// thus we don't want to spam analytics with the data we don't use.
		if (doNotTrackExtension || !isInitial()) {
			return;
		}

		// WARN: Complexity alert! The logic of fmp/tti/duration calculation is convoluted
		// here because of several reasons. Due to the timing of creating performance marks
		// that we use here, we may report extension timing *before* TTI measure is evaluated,
		// since TTI measure is waiting for several page segments. Thus on initial load we have
		// to rely on other reference points, that are available before extensions are rendered.
		const pageTTI = isInitial()
			? getPerformanceEntry('rendererRendered.end')?.pop()?.startTime
			: getPerformanceEntry('browser-metric:tti')?.pop()?.duration;

		const pageFMP = isInitial() ? getPerformanceEntry('CFP-63.ssr-ttr')?.pop()?.startTime : pageTTI;

		const clientRouteStart = getPerformanceEntry('browser-metric.start')?.pop()?.startTime;

		const ssrPreloadFailure = getPreloadFailures(macroId) || '';
		const ssrGeneralFailure = getGeneralFailures() || '';

		const isExtensionSSRed = isInitial()
			? getExtensionSSRMark(macroId) || isBlocklisted(ssrPreloadFailure)
			: NOT_SSRED;

		// On transition we cannot reliably expect that "browser-metric:tti" measure is there
		// before macro rendering. Thus, use routing start as a base point for extension performance
		// measurement on transition. Note that FMP=TTI on transition.
		const startTime = (isInitial() ? pageFMP : clientRouteStart) ?? 0;
		const customData = {
			isExtensionSSRed,
			extensionIndex,
			extensionKey,
			contentId,
			pageFMP: pageFMP ?? 0,
			pageTTI: pageTTI ?? 0,
			isATF: isMarkerAboveTheFold(markerRef.current),
			ssrPreloadFailure: isUnsupportedExtension
				? 'unsupportedContentInternalExtension'
				: ssrPreloadFailure || ssrGeneralFailure,
			clientRouteStart: clientRouteStart ?? 0,
		};

		const metric = LEGACY_EXTENSION_LOADING_METRIC(`${extensionKey}.${contentId}.${localId}`);
		metric.start({ startTime });
		metric.stop({ customData });
	}, [
		doNotTrackExtension,
		extensionBlocklist,
		extensionKey,
		extensionIndex,
		isUnsupportedExtension,
		routeName,
		localId,
		contentId,
		macroId,
	]);

	return <span ref={markerRef} />;
};
